import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
    apiKey: "AIzaSyALSyNYVGGIio-MHhtYup1HpinYbzr_S30",
    version: "weekly",
    libraries: ["maps", "marker"],
});

interface MapMarkersResponse {
    mode: string;
    count: number;
    marker_groups: MapMarkerGroupInfo[];
    markers: MapMarkerInfo[];
    minerals: Record<string, number>;
}

interface MapMarkerGroupInfo {
    count: number;
    latitude: number;
    longitude: number;
    name: string;
};

interface MapMarkerInfo {
    latitude: number;
    longitude: number;

    site_name: string;
    commod1: string;
    commod2: string;
    commod3: string;

    dep_id: number;
}

async function initMap(): Promise<void> {
    const { Map: GoogleMap } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    var map: google.maps.Map;
    let request_index = 0;
    let regional_markers: InstanceType<typeof AdvancedMarkerElement>[] = [];
    let markers_by_depid: Map<number, InstanceType<typeof AdvancedMarkerElement>> = new Map();
    var openmarkeriw: google.maps.InfoWindow | null = null;

    function getCookie(c_name: string) {
        if (document.cookie.length > 0) {
            let c_start = document.cookie.indexOf(c_name + "=");
            if (c_start != -1) {
                c_start = c_start + c_name.length + 1;
                let c_end = document.cookie.indexOf(";", c_start);
                if (c_end == -1) c_end = document.cookie.length;
                return unescape(document.cookie.substring(c_start, c_end));
            }
        }
        return "";
    }

    function setCookie(c_name: string, value: string) {
        let exdate = new Date();
        exdate.setDate(exdate.getDate() + 1);
        document.cookie = c_name + "=" + escape(value) + ";expires=" + exdate.toUTCString();
    }

    function getInitialLL() {
        let ll = getCookie('ll');

        if (ll == "")
            return new google.maps.LatLng(31, -106);
        else {
            let ll_components = ll.split(',');
            return new google.maps.LatLng(parseFloat(ll_components[0]), parseFloat(ll_components[1]));
        }
    }

    function getInitialZoom() {
        let ll = getCookie('ll');

        if (ll == "")
            return 6;
        else {
            let ll_components = ll.split(',');
            if (!ll_components[2])
                return 6;
            return ll_components[2];
        }
    }

    function initialize() {
        let latlng = getInitialLL();
        let initzoom = getInitialZoom();
        let myOptions = {
            zoom: (+initzoom),
            center: latlng,
            mapTypeId: google.maps.MapTypeId.TERRAIN,
            mapId: "MRM",
        };
        map = new google.maps.Map(document.getElementById("map_canvas")!, myOptions);

        google.maps.event.addListener(map, 'bounds_changed', requestUpdateMap);
    };

    function clearRegionMarkers() {
        regional_markers.forEach(marker => {
            marker.map = null;
        });
        regional_markers = [];
    }

    function clearMarkers() {
        markers_by_depid.forEach((marker, dep_id) => {
            marker.map = null;
        });
        markers_by_depid.clear();
        regional_markers.forEach(marker => {
            marker.map = null;
        });
        regional_markers = [];
    }

    function retainMarkers(markers_to_keep: number[]) {
        markers_by_depid.forEach((marker, dep_id) => {
            if (!markers_to_keep.includes(dep_id)) {
                marker.map = null;
                markers_by_depid.delete(dep_id);
            }
        });
    }

    function requestUpdateMap() {
        let zoom = map.getZoom()!;

        if (zoom > 2) {
            document.getElementById('mode_bounds')!.classList.add('mode_active');
            document.getElementById('mode_county')!.classList.add('mode_active');
            document.getElementById('mode_state')!.classList.add('mode_active');
            document.getElementById('mode_country')!.classList.add('mode_active');
            document.getElementById('mode_region')!.classList.add('mode_active');

            document.getElementById('mode_bounds')!.classList.remove('mode_selected');
            document.getElementById('mode_county')!.classList.remove('mode_selected');
            document.getElementById('mode_state')!.classList.remove('mode_selected');
            document.getElementById('mode_country')!.classList.remove('mode_selected');
            document.getElementById('mode_region')!.classList.remove('mode_selected');

            let mode = 'bounds';
            if (zoom < 10) {
                document.getElementById('mode_bounds')!.classList.remove('mode_active');
                mode = 'county';
            } else if (zoom < 8) {
                document.getElementById('mode_county')!.classList.remove('mode_active');
                mode = 'state';
            } else if (zoom < 6) {
                document.getElementById('mode_state')!.classList.remove('mode_active');
                mode = 'country';
            } else if (zoom < 4) {
                document.getElementById('mode_country')!.classList.remove('mode_active');
                mode = 'region';
            }

            switch (mode) {
                case 'bounds':
                    document.getElementById('mode_bounds')!.classList.add('mode_selected');
                    break;
                case 'county':
                    document.getElementById('mode_county')!.classList.add('mode_selected');
                    break;
                case 'state':
                    document.getElementById('mode_state')!.classList.add('mode_selected');
                    break;
                case 'country':
                    document.getElementById('mode_country')!.classList.add('mode_selected');
                    break;
                case 'region':
                    document.getElementById('mode_region')!.classList.add('mode_selected');
                    break;
            }

            let bounds = map.getBounds()!;

            const params = new URLSearchParams({
                mode: mode,
                zoom: String(map.getZoom()),
                lat_low: String(bounds.getSouthWest().lat()),
                lat_high: String(bounds.getNorthEast().lat()),
                lng_low: String(bounds.getSouthWest().lng()),
                lng_high: String(bounds.getNorthEast().lng()),
            });

            fetch(`/api/map?${params.toString()}`).then(
                (response) => {
                    if (response.ok) {
                        response.json().then((vals) => {
                            updateMap(vals);
                        });
                    } else {
                        console.error("Error fetching data");
                    }
                }
            );
        }
        else {
            console.error("Invalid zoom level");
            clearMarkers();
        }
    }

    function createIW(marker: InstanceType<typeof AdvancedMarkerElement>, markerinfo: MapMarkerInfo) {
        if (openmarkeriw)
            openmarkeriw.close();

        let content = '<h3>' + markerinfo.site_name + '</h3>';
        if (markerinfo.commod1)
            content += '<b>Major mineral:</b>' + markerinfo.commod1 + '<br />';
        if (markerinfo.commod2)
            content += '<b>Minor mineral:</b>' + markerinfo.commod2 + '<br />';
        if (markerinfo.commod3)
            content += '<b>Trace mineral:</b>' + markerinfo.commod3 + '<br />';

        content += '<a href=\'detail/' + markerinfo.dep_id + '\'>more details</a>';

        let infowindow = new google.maps.InfoWindow({
            content: content
        });
        infowindow.open(map, marker);

        document.querySelectorAll('.gm-style-iw').forEach(el => {
            el.classList.remove('bg-body'); // Remove old class to force reapply
            el.classList.add('bg-body');
        });

        openmarkeriw = infowindow;
    }

    function createIwLoader(marker: InstanceType<typeof AdvancedMarkerElement>, data: MapMarkerInfo) {
        return function () { createIW(marker, data); };
    }

    function updateMap(vals: MapMarkersResponse) {
        if (vals.mode == 'bounds') {
            //update mineral list
            let str = "<h3>Minerals present</h3><ul class='sidebar-mineral-list'>";

            let mineral_entries = Object.entries(vals.minerals);
            mineral_entries.sort((a, b) => b[1] - a[1]);

            for (const [mineral_name, mineral_count] of mineral_entries) {
                const marker_url = new URL("img/mine_sm.svg", window.location.origin);
                marker_url.searchParams.set("mineral", mineral_name);
                str += `<li class='sidebar-mineral-item'><img src=\"${marker_url}\" /><span>${mineral_name} (${mineral_count})</span></li>`;
            }
            str += "</ul>"

            document.getElementById('filter')!.innerHTML = str;

            //end update

            // In case we just switched from a different mode
            clearRegionMarkers();

            let kept_markers = vals.markers.map(marker_info => marker_info.dep_id);
            retainMarkers(kept_markers);

            vals.markers.forEach(marker_info => {
                if (markers_by_depid.has(marker_info.dep_id)) {
                    return;
                }

                const div = document.createElement("div");

                // if (vals.markers.length < 500) {
                const marker_url = new URL("img/mine.svg", window.location.origin);
                marker_url.searchParams.set("mineral", marker_info.commod1);

                div.style.width = "32px";
                div.style.height = "32px";
                div.style.backgroundImage = `url('${marker_url}')`;
                div.style.backgroundSize = "contain";
                div.style.backgroundRepeat = "no-repeat";
                div.style.backgroundPosition = "center bottom";
                // } else {
                //     const marker_url = new URL("img/mine_sm.svg", window.location.origin);
                //     marker_url.searchParams.set("mineral", marker_info.commod1);

                //     div.style.width = "12px";
                //     div.style.height = "12px";
                //     div.style.backgroundImage = `url('${marker_url}')`;
                //     div.style.backgroundSize = "contain";
                //     div.style.backgroundRepeat = "no-repeat";
                //     div.style.backgroundPosition = "center center";

                // }

                let new_marker = new AdvancedMarkerElement({
                    position: new google.maps.LatLng(marker_info.latitude, marker_info.longitude),
                    map: map,
                    content: div,
                    title: marker_info.site_name
                });
                google.maps.event.addListener(new_marker, 'click', createIwLoader(new_marker, marker_info));

                markers_by_depid.set(marker_info.dep_id, new_marker);
            });
        }

        if (vals.mode == 'county' || vals.mode == 'state' || vals.mode == 'country' || vals.mode == 'region') {
            clearMarkers();

            let str = '<h3>' + vals.mode + ' results</h3><ul>';

            vals.marker_groups.forEach(marker_group => {
                const marker_icon = document.createElement("img");
                marker_icon.src = `img/${vals.mode}.png`;
                let new_marker = new AdvancedMarkerElement({
                    position: new google.maps.LatLng(marker_group.latitude, marker_group.longitude),
                    map: map,
                    content: marker_icon,
                    title: vals.mode + ' (' + marker_group.count + ')'
                });

                regional_markers.push(new_marker);

                str += '<li>' + marker_group.name + '(' + marker_group.count + ')</li>';
            }
            );
            str += "</ul>";

            document.getElementById('filter')!.innerHTML = str;
        }

        {
            let ll = new Array(map.getCenter()!.lat(), map.getCenter()!.lng(), map.getZoom());
            let ll_val = ll.join(',');
            document.cookie = `ll=${ll_val}; path=/; Secure; SameSite=Strict`;
        }
    }

    initialize();
}

loader.load().then(() => {
    initMap();
});